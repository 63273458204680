var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.patientSubmitted
    ? _c("div", [
        _c("div", { staticClass: "info" }, [
          _vm._v(
            "Add or remove insurance providers from this patient's preauthorization request"
          ),
        ]),
        !_vm.insurancesSubmitted
          ? _c(
              "div",
              [
                _vm._l(_vm.insurances, function (insurance, index) {
                  return _c(
                    "div",
                    { key: insurance.payer_id },
                    [
                      _c(
                        "b-card-group",
                        {
                          staticStyle: {
                            "margin-top": "10px",
                            "max-width": "1000px",
                          },
                        },
                        [
                          _c(
                            "b-card",
                            { staticClass: "card-bottom shadow" },
                            [
                              _c(
                                "div",
                                { staticClass: "card-top" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        float: "right",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "close-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeInsurance(
                                                insurance.payer_id,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass: "mr-2",
                                            attrs: { icon: "x" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("b-table", {
                                    staticClass: "payer-table",
                                    attrs: {
                                      items: [insurance],
                                      fields: _vm.payerDisplayFields,
                                      borderless: true,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-container",
                                {},
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "selecter-label" },
                                            [_vm._v("Member ID")]
                                          ),
                                          _c("b-form-input", {
                                            model: {
                                              value: insurance.member_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  insurance,
                                                  "member_id",
                                                  $$v
                                                )
                                              },
                                              expression: "insurance.member_id",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "selecter-label" },
                                            [_vm._v("Group No.")]
                                          ),
                                          _c("b-form-input", {
                                            model: {
                                              value: insurance.group_no,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  insurance,
                                                  "group_no",
                                                  $$v
                                                )
                                              },
                                              expression: "insurance.group_no",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c("b-col", [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "selecter-label",
                                                  },
                                                  [_vm._v("Effective Dates")]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "date",
                                                      placeholder:
                                                        insurance.effective_start_date ||
                                                        "",
                                                      "date-format-options": {
                                                        year: "numeric",
                                                        month: "numeric",
                                                        day: "numeric",
                                                      },
                                                      locale: "en",
                                                    },
                                                    model: {
                                                      value:
                                                        insurance.effective_start_date,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          insurance,
                                                          "effective_start_date",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "insurance.effective_start_date",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                {
                                                  staticStyle: {
                                                    "padding-top": ".75rem",
                                                  },
                                                  attrs: { cols: "1.5" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "selecter-label",
                                                    },
                                                    [_vm._v("to")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-col",
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "date",
                                                      placeholder:
                                                        insurance.effective_end_date ||
                                                        "",
                                                      "date-format-options": {
                                                        year: "numeric",
                                                        month: "numeric",
                                                        day: "numeric",
                                                      },
                                                      locale: "en",
                                                    },
                                                    model: {
                                                      value:
                                                        insurance.effective_end_date,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          insurance,
                                                          "effective_end_date",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "insurance.effective_end_date",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    {},
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "selecter-label" },
                                            [_vm._v("Subscriber's name")]
                                          ),
                                          _c("b-form-input", {
                                            model: {
                                              value: insurance.subscriber_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  insurance,
                                                  "subscriber_name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "insurance.subscriber_name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "selecter-label" },
                                            [
                                              _vm._v(
                                                "Relationship to subscriber"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-form-radio-group",
                                            {
                                              staticClass: "selecter-label",
                                              staticStyle: {
                                                "margin-top": "10px",
                                              },
                                              model: {
                                                value:
                                                  insurance.subscriber_relationship,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    insurance,
                                                    "subscriber_relationship",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "insurance.subscriber_relationship",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-radio",
                                                { attrs: { value: "0" } },
                                                [_vm._v("Self")]
                                              ),
                                              _c(
                                                "b-form-radio",
                                                { attrs: { value: "1" } },
                                                [_vm._v("Spouse")]
                                              ),
                                              _c(
                                                "b-form-radio",
                                                { attrs: { value: "2" } },
                                                [_vm._v("Child")]
                                              ),
                                              _c(
                                                "b-form-radio",
                                                { attrs: { value: "3" } },
                                                [_vm._v("Other")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "1" } },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "selecter-label" },
                                            [_vm._v("P/S/T")]
                                          ),
                                          _c("b-form-select", {
                                            attrs: {
                                              options: [
                                                { text: "P", value: "PRIMARY" },
                                                {
                                                  text: "S",
                                                  value: "SECONDARY",
                                                },
                                                {
                                                  text: "T",
                                                  value: "TERTIARY",
                                                },
                                                { text: "U", value: "UNKNOWN" },
                                              ],
                                            },
                                            model: {
                                              value:
                                                insurance.insurance_primacy,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  insurance,
                                                  "insurance_primacy",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "insurance.insurance_primacy",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "mt-auto",
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "selecter-label mt-0",
                                            },
                                            [_vm._v("Verified active")]
                                          ),
                                          _c("b-form-checkbox", {
                                            attrs: { switch: "" },
                                            model: {
                                              value: insurance.active_benefits,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  insurance,
                                                  "active_benefits",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "insurance.active_benefits",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  insurance.insurance_primacy === "PRIMARY"
                                    ? _c(
                                        "b-row",
                                        {},
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "selecter-label",
                                                },
                                                [_vm._v("Co-pay")]
                                              ),
                                              _c(
                                                "b-input-group",
                                                { attrs: { prepend: "$" } },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: { type: "number" },
                                                    model: {
                                                      value: insurance.copay,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          insurance,
                                                          "copay",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "insurance.copay",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "selecter-label",
                                                },
                                                [_vm._v("Deductible not met")]
                                              ),
                                              _c(
                                                "b-input-group",
                                                { attrs: { prepend: "$" } },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: { type: "number" },
                                                    model: {
                                                      value:
                                                        insurance.deductible,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          insurance,
                                                          "deductible",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "insurance.deductible",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "selecter-label",
                                                },
                                                [_vm._v("OOP not met")]
                                              ),
                                              _c(
                                                "b-input-group",
                                                { attrs: { prepend: "$" } },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: { type: "number" },
                                                    model: {
                                                      value:
                                                        insurance.out_of_pocket,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          insurance,
                                                          "out_of_pocket",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "insurance.out_of_pocket",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "selecter-label",
                                                },
                                                [_vm._v("Co-insurance")]
                                              ),
                                              _c(
                                                "b-input-group",
                                                { attrs: { append: "%" } },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: { type: "number" },
                                                    model: {
                                                      value:
                                                        insurance.patient_responsibility,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          insurance,
                                                          "patient_responsibility",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "insurance.patient_responsibility",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "mt-auto",
                                              attrs: { cols: "3" },
                                            },
                                            [
                                              _c(
                                                "b-form-radio-group",
                                                {
                                                  staticClass: "mb-2",
                                                  model: {
                                                    value:
                                                      insurance.patient_responsibility_type,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        insurance,
                                                        "patient_responsibility_type",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "insurance.patient_responsibility_type",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-radio",
                                                    { attrs: { value: "0" } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "selecter-label",
                                                        },
                                                        [_vm._v("Deductible")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-form-radio",
                                                    { attrs: { value: "1" } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "selecter-label",
                                                        },
                                                        [_vm._v("OOP")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                _c("br"),
                !_vm.showPayerSelection
                  ? _c(
                      "b-row",
                      { attrs: { "align-v": "center" } },
                      [
                        _c("b-col", { attrs: { cols: "6" } }, [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _vm.insurances.length > 0
                                ? _c("div", { staticClass: "info mr-3" }, [
                                    _vm._v("Add another insurance provider?"),
                                  ])
                                : _vm._e(),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.new-insurance",
                                      modifiers: { "new-insurance": true },
                                    },
                                  ],
                                  staticClass: "avo-primary-btn mr-4",
                                },
                                [
                                  _c("b-icon", {
                                    staticClass: "mr-2",
                                    attrs: { icon: "plus-circle" },
                                  }),
                                  _vm._v("Add insurance"),
                                ],
                                1
                              ),
                              _c("insurance-add", {
                                attrs: {
                                  "patient-id": _vm.patient.patient_id,
                                  "current-insurances": _vm.insurances,
                                },
                                on: {
                                  submitted: function ($event) {
                                    return _vm.getPatientInsurances()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _vm.insurances.length > 0
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "avo-primary-btn",
                                    on: { click: _vm.submitInsurances },
                                  },
                                  [_vm._v("Continue")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticStyle: { "text-align": "center" } },
                          [
                            _vm._v(
                              " Payer Selection Component (AV-282) goes here "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                _c("br"),
                _c("br"),
              ],
              2
            )
          : _c(
              "div",
              [
                _c(
                  "b-card-group",
                  {
                    staticStyle: {
                      "margin-top": "10px",
                      "max-width": "1500px",
                    },
                  },
                  [
                    _c(
                      "b-card",
                      { staticClass: "payer-display-card" },
                      [
                        _c(
                          "b-table-simple",
                          { staticClass: "payer-display-table" },
                          [
                            _c(
                              "b-thead",
                              [
                                _c(
                                  "b-tr",
                                  [
                                    _c("b-th", [_vm._v("Insurance provider")]),
                                    _c("b-th", [_vm._v("Phone")]),
                                    _c("b-th", [_vm._v("Fax")]),
                                    _c("b-th", [_vm._v("Address")]),
                                    _c("b-th", [_vm._v("ID")]),
                                    _c("b-th", [_vm._v("Status")]),
                                    _c("b-th"),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(_vm.insurances, function (insurance, index) {
                              return _c(
                                "b-tbody",
                                { key: insurance.payer_id },
                                [
                                  _c(
                                    "b-tr",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleRow(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("b-td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(insurance.name || "N/A") +
                                            " "
                                        ),
                                      ]),
                                      _c("b-td", [
                                        _vm._v(
                                          _vm._s(insurance.phone || "N/A")
                                        ),
                                      ]),
                                      _c("b-td", [
                                        _vm._v(_vm._s(insurance.fax || "N/A")),
                                      ]),
                                      _c("b-td", [
                                        _vm._v(
                                          _vm._s(
                                            insurance.full_address || "N/A"
                                          )
                                        ),
                                      ]),
                                      _c("b-td", [
                                        _vm._v(_vm._s(insurance.payer_id)),
                                      ]),
                                      _c(
                                        "b-td",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "status-label" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.insuranceStatus(index)
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-td",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                            padding: "24px 4px 0 0",
                                          },
                                        },
                                        [
                                          _vm.toggled[index]
                                            ? _c("b-icon", {
                                                attrs: {
                                                  "font-scale": "1.5",
                                                  icon: "chevron-up",
                                                },
                                              })
                                            : _c("b-icon", {
                                                attrs: {
                                                  "font-scale": "1.5",
                                                  icon: "chevron-down",
                                                },
                                              }),
                                          _vm.insurances.length > 1
                                            ? _c("b-icon", {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: {
                                                  "font-scale": "1.5",
                                                  icon: "x",
                                                },
                                                on: {
                                                  click: [
                                                    function ($event) {
                                                      $event.stopPropagation()
                                                    },
                                                    function ($event) {
                                                      return _vm.removeInsurance(
                                                        insurance.payer_id,
                                                        index
                                                      )
                                                    },
                                                  ],
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.toggled[index]
                                    ? _c(
                                        "b-tr",
                                        { staticClass: "insurance-dropdown" },
                                        [
                                          _c(
                                            "b-td",
                                            { attrs: { colspan: "8" } },
                                            [
                                              _c(
                                                "b-container",
                                                {
                                                  staticStyle: {
                                                    "padding-top": "10px",
                                                    "padding-bottom": "50px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c("label", [
                                                            _vm._v("Member ID"),
                                                          ]),
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              disabled: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.insurances[
                                                                  index
                                                                ].member_id,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .insurances[
                                                                      index
                                                                    ],
                                                                    "member_id",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "insurances[index].member_id",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c("label", [
                                                            _vm._v("Group No."),
                                                          ]),
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              disabled: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.insurances[
                                                                  index
                                                                ].group_no,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .insurances[
                                                                      index
                                                                    ],
                                                                    "group_no",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "insurances[index].group_no",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "6" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-row",
                                                            [
                                                              _c("b-col", [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    "Effective Dates"
                                                                  ),
                                                                ]),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-row",
                                                            [
                                                              _c(
                                                                "b-col",
                                                                [
                                                                  _c(
                                                                    "b-form-input",
                                                                    {
                                                                      attrs: {
                                                                        type: "date",
                                                                        placeholder:
                                                                          _vm
                                                                            .insurances[
                                                                            index
                                                                          ]
                                                                            .effective_start_date ||
                                                                          "",
                                                                        "date-format-options":
                                                                          {
                                                                            year: "numeric",
                                                                            month:
                                                                              "numeric",
                                                                            day: "numeric",
                                                                          },
                                                                        locale:
                                                                          "en",
                                                                        disabled:
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .insurances[
                                                                            index
                                                                          ]
                                                                            .effective_start_date,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .insurances[
                                                                                index
                                                                              ],
                                                                              "effective_start_date",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "insurances[index].effective_start_date",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  staticStyle: {
                                                                    "padding-top":
                                                                      ".75rem",
                                                                  },
                                                                  attrs: {
                                                                    cols: "1.5",
                                                                  },
                                                                },
                                                                [_vm._v(" to ")]
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                [
                                                                  _c(
                                                                    "b-form-input",
                                                                    {
                                                                      attrs: {
                                                                        type: "date",
                                                                        placeholder:
                                                                          _vm
                                                                            .insurances[
                                                                            index
                                                                          ]
                                                                            .effective_end_date ||
                                                                          "",
                                                                        "date-format-options":
                                                                          {
                                                                            year: "numeric",
                                                                            month:
                                                                              "numeric",
                                                                            day: "numeric",
                                                                          },
                                                                        locale:
                                                                          "en",
                                                                        disabled:
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .insurances[
                                                                            index
                                                                          ]
                                                                            .effective_end_date,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .insurances[
                                                                                index
                                                                              ],
                                                                              "effective_end_date",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "insurances[index].effective_end_date",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-row",
                                                    { staticClass: "mt-2" },
                                                    [
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              "Subscriber's name"
                                                            ),
                                                          ]),
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              disabled: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.insurances[
                                                                  index
                                                                ]
                                                                  .subscriber_name,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .insurances[
                                                                      index
                                                                    ],
                                                                    "subscriber_name",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "insurances[index].subscriber_name",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              "Relationship to subscriber"
                                                            ),
                                                          ]),
                                                          _c(
                                                            "b-form-radio-group",
                                                            {
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "10px",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .insurances[
                                                                    index
                                                                  ]
                                                                    .subscriber_relationship,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .insurances[
                                                                        index
                                                                      ],
                                                                      "subscriber_relationship",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "insurances[index].subscriber_relationship",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-radio",
                                                                {
                                                                  attrs: {
                                                                    value: "0",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                },
                                                                [_vm._v("Self")]
                                                              ),
                                                              _c(
                                                                "b-form-radio",
                                                                {
                                                                  attrs: {
                                                                    value: "1",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Spouse"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "b-form-radio",
                                                                {
                                                                  attrs: {
                                                                    value: "2",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Child"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "b-form-radio",
                                                                {
                                                                  attrs: {
                                                                    value: "3",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Other"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              disabled: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.insurances[
                                                                  index
                                                                ]
                                                                  .insurance_primacy,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .insurances[
                                                                      index
                                                                    ],
                                                                    "insurance_primacy",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "insurances[index].insurance_primacy",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              attrs: {
                                                                switch: "",
                                                                disabled: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .insurances[
                                                                    index
                                                                  ]
                                                                    .active_benefits,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .insurances[
                                                                        index
                                                                      ],
                                                                      "active_benefits",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "insurances[index].active_benefits",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Active benefits "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-row",
                                                    { staticClass: "mt-2" },
                                                    [
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c("label", [
                                                            _vm._v("Co-pay"),
                                                          ]),
                                                          _c(
                                                            "b-input-group",
                                                            {
                                                              attrs: {
                                                                prepend: "$",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    type: "number",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .insurances[
                                                                        index
                                                                      ].copay,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .insurances[
                                                                            index
                                                                          ],
                                                                          "copay",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "insurances[index].copay",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              "Deductible not met"
                                                            ),
                                                          ]),
                                                          _c(
                                                            "b-input-group",
                                                            {
                                                              attrs: {
                                                                prepend: "$",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    type: "number",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .insurances[
                                                                        index
                                                                      ]
                                                                        .deductible,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .insurances[
                                                                            index
                                                                          ],
                                                                          "deductible",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "insurances[index].deductible",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              "OOP not met"
                                                            ),
                                                          ]),
                                                          _c(
                                                            "b-input-group",
                                                            {
                                                              attrs: {
                                                                prepend: "$",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    type: "number",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .insurances[
                                                                        index
                                                                      ]
                                                                        .out_of_pocket,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .insurances[
                                                                            index
                                                                          ],
                                                                          "out_of_pocket",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "insurances[index].out_of_pocket",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              "Coinsurane"
                                                            ),
                                                          ]),
                                                          _c(
                                                            "b-input-group",
                                                            {
                                                              attrs: {
                                                                append: "%",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    type: "number",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .insurances[
                                                                        index
                                                                      ]
                                                                        .patient_responsibility,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .insurances[
                                                                            index
                                                                          ],
                                                                          "patient_responsibility",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "insurances[index].patient_responsibility",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-radio-group",
                                                            {
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "40px",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .insurances[
                                                                    index
                                                                  ]
                                                                    .patient_responsibility_type,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .insurances[
                                                                        index
                                                                      ],
                                                                      "patient_responsibility_type",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "insurances[index].patient_responsibility_type",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-radio",
                                                                {
                                                                  attrs: {
                                                                    value: "0",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Deductible"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "b-form-radio",
                                                                {
                                                                  attrs: {
                                                                    value: "1",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                },
                                                                [_vm._v("OOP")]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }