var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", [
            _c("div", { staticClass: "data-label" }, [
              _vm._v("Primary Insurance:"),
            ]),
            _c("div", [_vm._v(_vm._s(_vm.name))]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", { staticClass: "data-label" }, [
              _vm._v("Preauths Submission Portal"),
            ]),
            _c("div", [_vm._v(" " + _vm._s(_vm.preauths_portal || "-") + " ")]),
          ]),
          _c("b-col", [
            _c("div", { staticClass: "data-label" }, [
              _vm._v("Submission Phone"),
            ]),
            _c("div", [_vm._v(" " + _vm._s(_vm.preauths_phone || "-") + " ")]),
          ]),
          _c("b-col", [
            _c("div", { staticClass: "data-label" }, [
              _vm._v("Submission Fax"),
            ]),
            _c("div", [_vm._v(" " + _vm._s(_vm.preauths_fax || "-") + " ")]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }