var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "schedule-container mb-5" },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-between mb-4",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-between",
                  staticStyle: { width: "550px" },
                },
                [
                  _c(
                    "b-dropdown",
                    { attrs: { id: "dropdown-1", text: _vm.dropdownTitle } },
                    [
                      _c(
                        "b-dropdown-item",
                        { on: { click: _vm.toggleCalendarWeek } },
                        [_vm._v("Week")]
                      ),
                      _c(
                        "b-dropdown-item",
                        { on: { click: _vm.toggleCalendarMonth } },
                        [_vm._v("Month")]
                      ),
                      _c(
                        "b-dropdown-item",
                        { on: { click: _vm.toggleCalendarSchedule } },
                        [_vm._v("Schedule")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c("div", { staticClass: "date-header-text mr-2" }, [
                        _vm._v(_vm._s(_vm.getTitle)),
                      ]),
                      _c("b-icon", {
                        staticClass: "mr-2",
                        attrs: {
                          role: "button",
                          icon: "chevron-left",
                          "font-scale": "1.5",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.clickPrev()
                          },
                        },
                      }),
                      _c("b-icon", {
                        attrs: {
                          role: "button",
                          icon: "chevron-right",
                          "font-scale": "1.5",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.clickNext()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "avo-basic-btn",
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.clickToday()
                        },
                      },
                    },
                    [_vm._v("Today")]
                  ),
                ],
                1
              ),
              _c(
                "b-button-group",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "avo-primary-btn mr-2",
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.printSchedule()
                        },
                      },
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "printer" },
                      }),
                      _vm._v("Print Schedule "),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "avo-primary-btn",
                      attrs: {
                        disabled: _vm.visitHasPreauth,
                        variant: "outline-primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showNewPreauth()
                        },
                      },
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "plus" },
                      }),
                      _vm._v(
                        _vm._s(
                          _vm.showManagePreauthQuickView
                            ? "Add Preauth"
                            : "New Visit"
                        ) + " "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-alert",
            {
              attrs: { variant: "success", dismissible: "" },
              model: {
                value: _vm.showNewPreauthSuccess,
                callback: function ($$v) {
                  _vm.showNewPreauthSuccess = $$v
                },
                expression: "showNewPreauthSuccess",
              },
            },
            [_c("b", [_vm._v("Successfully added new preauth")])]
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showManagePreauthQuickView,
                      expression: "!showManagePreauthQuickView",
                    },
                  ],
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "schedule shadow-custom" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "calendar-sidebar do-not-show-on-print",
                          staticStyle: { "max-width": "none" },
                          attrs: { cols: "2" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "calendar-icon-lrg" },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "calendar3",
                                  animation: _vm.eventsLoading ? "throb" : "",
                                  scale: "1.25",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("b-calendar", {
                            staticClass: "mb-4",
                            on: { context: _vm.onContext },
                            model: {
                              value: _vm.datePickerValue,
                              callback: function ($$v) {
                                _vm.datePickerValue = $$v
                              },
                              expression: "datePickerValue",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "section-header-text mb-2" },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-top": "auto" } },
                                [_vm._v("Filters")]
                              ),
                              _vm.filtersAreApplied
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "avo-basic-btn mr-2",
                                      attrs: { variant: "outline-primary" },
                                      on: { click: _vm.clearFilters },
                                    },
                                    [
                                      _c("b-icon", {
                                        staticClass: "mr-1",
                                        attrs: { icon: "x-circle" },
                                      }),
                                      _vm._v(" Clear Filters"),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-input-group",
                            { staticClass: "search-group mb-4" },
                            [
                              _c("b-input-group-prepend", [
                                _c(
                                  "span",
                                  { staticClass: "input-group-text" },
                                  [_c("b-icon", { attrs: { icon: "search" } })],
                                  1
                                ),
                              ]),
                              _c("b-form-input", {
                                staticClass: "preauth-search-input pl-1",
                                attrs: {
                                  debounce: "500",
                                  type: "search",
                                  placeholder: "Search patient or account #",
                                },
                                model: {
                                  value: _vm.patientSearchText,
                                  callback: function ($$v) {
                                    _vm.patientSearchText = $$v
                                  },
                                  expression: "patientSearchText",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("label", { staticClass: "selecter-label" }, [
                            _vm._v("Provider"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "multiselect",
                                {
                                  attrs: {
                                    placeholder: "Filter by provider",
                                    "custom-label": _vm.providerLabel,
                                    "track-by": "provider_id",
                                    options: _vm.selectableProviders,
                                    loading: _vm.providersLoading,
                                    "internal-search": false,
                                    "show-labels": false,
                                    showNoOptions: true,
                                    "allow-empty": true,
                                  },
                                  on: {
                                    "search-change": _vm.debounceProviders,
                                  },
                                  model: {
                                    value: _vm.selectedProvider,
                                    callback: function ($$v) {
                                      _vm.selectedProvider = $$v
                                    },
                                    expression: "selectedProvider",
                                  },
                                },
                                [
                                  _c("template", { slot: "noResult" }, [
                                    _vm._v(
                                      " No providers matched your search "
                                    ),
                                  ]),
                                  _c("template", { slot: "noOptions" }, [
                                    _vm._v(" Start typing a provider name... "),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c("label", { staticClass: "selecter-label" }, [
                            _vm._v("Practice"),
                          ]),
                          _c(
                            "multiselect",
                            {
                              attrs: {
                                placeholder: "Filter by practice",
                                "custom-label": _vm.practiceLabel,
                                "track-by": "practice_id",
                                options: _vm.selectablePractices,
                                loading: _vm.practicesLoading,
                                "internal-search": false,
                                "show-labels": false,
                                showNoOptions: true,
                                "allow-empty": true,
                              },
                              on: { "search-change": _vm.debouncePractices },
                              model: {
                                value: _vm.selectedPractice,
                                callback: function ($$v) {
                                  _vm.selectedPractice = $$v
                                },
                                expression: "selectedPractice",
                              },
                            },
                            [
                              _c("template", { slot: "noResult" }, [
                                _vm._v(" No practices matched your search "),
                              ]),
                              _c("template", { slot: "noOptions" }, [
                                _vm._v(" Start typing a practice name... "),
                              ]),
                            ],
                            2
                          ),
                          _c("label", { staticClass: "selecter-label" }, [
                            _vm._v("Visit Type"),
                          ]),
                          _c(
                            "multiselect",
                            {
                              attrs: {
                                placeholder: "Filter by visit type",
                                "custom-label": _vm.procedureTypeLabel,
                                "track-by": "procedure_type_id",
                                options: _vm.selectableProcedureTypes,
                                loading: _vm.procedureTypesLoading,
                                "internal-search": true,
                                "show-labels": false,
                                showNoOptions: true,
                                "allow-empty": true,
                                multiple: true,
                              },
                              model: {
                                value: _vm.selectedProcedureTypes,
                                callback: function ($$v) {
                                  _vm.selectedProcedureTypes = $$v
                                },
                                expression: "selectedProcedureTypes",
                              },
                            },
                            [
                              _c("template", { slot: "noOptions" }, [
                                _vm._v(
                                  " Select a practice to see visit types "
                                ),
                              ]),
                            ],
                            2
                          ),
                          _c("label", { staticClass: "selecter-label" }, [
                            _vm._v("Location"),
                          ]),
                          _c(
                            "multiselect",
                            {
                              attrs: {
                                placeholder: "Filter by practice location",
                                "custom-label": _vm.locationLabel,
                                "track-by": "location_id",
                                options: _vm.selectableLocations,
                                loading: _vm.locationsLoading,
                                "internal-search": false,
                                "show-labels": false,
                                showNoOptions: true,
                                "allow-empty": true,
                              },
                              on: { "search-change": _vm.debounceLocations },
                              model: {
                                value: _vm.selectedLocation,
                                callback: function ($$v) {
                                  _vm.selectedLocation = $$v
                                },
                                expression: "selectedLocation",
                              },
                            },
                            [
                              _c("template", { slot: "noResult" }, [
                                _vm._v(" No locations matched your search "),
                              ]),
                              _c("template", { slot: "noOptions" }, [
                                _vm._v(
                                  " Start typing a location name or address... "
                                ),
                              ]),
                            ],
                            2
                          ),
                          _c("label", { staticClass: "selecter-label" }, [
                            _vm._v("Preauth Status"),
                          ]),
                          _c("multiselect", {
                            attrs: {
                              "hide-selected": true,
                              options: _vm.preauthStateMapArray,
                              "track-by": "state",
                              label: "name",
                              multiple: true,
                              "show-labels": false,
                              placeholder: "Filter by status",
                            },
                            model: {
                              value: _vm.selectedStates,
                              callback: function ($$v) {
                                _vm.selectedStates = $$v
                              },
                              expression: "selectedStates",
                            },
                          }),
                          _c("label", { staticClass: "selecter-label" }, [
                            _vm._v("Show/hide visits without preauths"),
                          ]),
                          _c("b-form-checkbox-group", {
                            staticClass: "checkbox-btn-group",
                            attrs: {
                              options: [
                                { text: "Preauths", value: 1 },
                                { text: "Visits Missing Preauths", value: 2 },
                              ],
                              buttons: "",
                              "button-variant": "outline-primary",
                              id: "preauths-checkbox-group",
                            },
                            model: {
                              value: _vm.showAllVisitsOrNoPreauths,
                              callback: function ($$v) {
                                _vm.showAllVisitsOrNoPreauths = $$v
                              },
                              expression: "showAllVisitsOrNoPreauths",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "px-0" },
                        [
                          _c("full-calendar", {
                            ref: "fullCalendar",
                            attrs: { options: _vm.calendarOptions },
                            scopedSlots: _vm._u([
                              {
                                key: "eventContent",
                                fn: function (arg) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "\n                    d-flex\n                    flex-column\n                    align-items-between\n                    justify-content-between\n                    p-1\n                  ",
                                      },
                                      [
                                        _vm.argEventPreauthStateName(arg) ==
                                        "No preauth"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mr-1 shield-no-preauth d-flex",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  [
                                                    _c("b-icon", {
                                                      attrs: {
                                                        icon: "shield-minus",
                                                        scale: "1.4",
                                                      },
                                                    }),
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.argEventPreauthStateName(
                                                              arg
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm.argEventPreauthStateName(arg) ==
                                            "Approved"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mr-1 shield-approved",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  [
                                                    _c("b-icon", {
                                                      attrs: {
                                                        icon: "shield-check",
                                                        scale: "1.4",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.argEventPreauthStateName(
                                                                arg
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm.argEventPreauthStateName(arg) ==
                                            "No Preauth Required"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mr-1 shield-approved",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  [
                                                    _c("b-icon", {
                                                      attrs: {
                                                        icon: "shield-check",
                                                        scale: "1.4",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.argEventPreauthStateName(
                                                                arg
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm.argEventPreauthStateName(arg) ==
                                            "Partially Approved"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mr-1 shield-partially-approved",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  [
                                                    _c("b-icon", {
                                                      attrs: {
                                                        icon: "shield-exclamation",
                                                        scale: "1.4",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.argEventPreauthStateName(
                                                                arg
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm.argEventPreauthStateName(arg) ==
                                            "Denied"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mr-1 shield-denied",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  [
                                                    _c("b-icon", {
                                                      attrs: {
                                                        icon: "shield-slash",
                                                        scale: "1.4",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.argEventPreauthStateName(
                                                                arg
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mr-1 shield-pending",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  [
                                                    _c("b-icon", {
                                                      attrs: {
                                                        icon: "shield",
                                                        scale: "1.4",
                                                      },
                                                    }),
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.argEventPreauthStateName(
                                                              arg
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                        _c(
                                          "div",
                                          { staticClass: "align-items-center" },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formatTime(
                                                      arg.event.extendedProps
                                                        .start_time
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            arg.event.extendedProps.end_time
                                              ? _c(
                                                  "b",
                                                  { staticClass: "ml-1" },
                                                  [
                                                    _vm._v(
                                                      " - " +
                                                        _vm._s(
                                                          _vm.formatTime(
                                                            arg.event
                                                              .extendedProps
                                                              .end_time
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pl-2 justify-content-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  color: "var(--dark)",
                                                  "font-size": "11px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      arg.event.extendedProps
                                                        .patient.full_name
                                                        ? arg.event
                                                            .extendedProps
                                                            .patient.full_name
                                                        : "Unknown Patient"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  color: "var(--gray)",
                                                  "font-size": "10px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Acct# " +
                                                    _vm._s(
                                                      arg.event.extendedProps
                                                        .patient.account_no ||
                                                        "N/A"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  color: "var(--gray)",
                                                  "font-size": "10px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      arg.event.extendedProps
                                                        .location_name
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("div", [
                                          arg.event.extendedProps
                                            .preauth_cptcodes
                                            ? _c(
                                                "div",
                                                [
                                                  _vm._l(
                                                    arg.event.extendedProps.preauth_cptcodes.slice(
                                                      0,
                                                      arg.event.extendedProps
                                                        .preauth_cptcodes
                                                        .length > 3
                                                        ? 2
                                                        : 3
                                                    ),
                                                    function (cptCode, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: index,
                                                          staticClass: "d-flex",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "event-cptcode",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    cptCode.code
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "event-cptdescription ml-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " - " +
                                                                  _vm._s(
                                                                    cptCode.short
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  arg.event.extendedProps
                                                    .preauth_cptcodes.length > 3
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "event-cptcode",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "+" +
                                                                _vm._s(
                                                                  arg.event
                                                                    .extendedProps
                                                                    .preauth_cptcodes
                                                                    .length - 2
                                                                ) +
                                                                " more codes"
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showManagePreauthQuickView
                ? _c(
                    "b-col",
                    { staticClass: "pr-0" },
                    [
                      _c(
                        "b-row",
                        { staticClass: "preview shadow-custom ml-2" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "preview px-0" },
                            [
                              _c("preauth-manage", {
                                on: {
                                  preauthsUpdated: function ($event) {
                                    return _vm.getCalendarEvents()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }