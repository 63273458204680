var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "auth-info-container mb-5" },
      [
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { cols: "4" } },
              [
                _c("label", { staticClass: "selecter-label" }, [
                  _vm._v("Status"),
                ]),
                _c("b-form-select", {
                  attrs: { options: _vm.preauthStateMap },
                  scopedSlots: _vm._u([
                    {
                      key: "first",
                      fn: function () {
                        return [
                          _c(
                            "b-form-select-option",
                            { attrs: { value: null, disabled: "" } },
                            [_vm._v("-- Please select an option --")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.info.preauth_state,
                    callback: function ($$v) {
                      _vm.$set(_vm.info, "preauth_state", $$v)
                    },
                    expression: "info.preauth_state",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-col",
              { attrs: { cols: "4" } },
              [
                _c("label", { staticClass: "selecter-label" }, [
                  _vm._v("Effective start date"),
                ]),
                _c("b-form-input", {
                  attrs: {
                    type: "date",
                    placeholder: "Choose a date",
                    "date-format-options": {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    },
                  },
                  model: {
                    value: _vm.info.effective_start_date,
                    callback: function ($$v) {
                      _vm.$set(_vm.info, "effective_start_date", $$v)
                    },
                    expression: "info.effective_start_date",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-col",
              { attrs: { cols: "4" } },
              [
                _c("label", { staticClass: "selecter-label" }, [
                  _vm._v("Effective end date"),
                ]),
                _c("b-form-input", {
                  attrs: {
                    type: "date",
                    placeholder: "Choose a date",
                    "date-format-options": {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    },
                  },
                  model: {
                    value: _vm.info.effective_end_date,
                    callback: function ($$v) {
                      _vm.$set(_vm.info, "effective_end_date", $$v)
                    },
                    expression: "info.effective_end_date",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { cols: "4" } },
              [
                _c("label", { staticClass: "selecter-label" }, [
                  _vm._v("Insurance authorization no."),
                ]),
                _c("b-form-input", {
                  model: {
                    value: _vm.info.insurance_auth_no,
                    callback: function ($$v) {
                      _vm.$set(_vm.info, "insurance_auth_no", $$v)
                    },
                    expression: "info.insurance_auth_no",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-col",
              { attrs: { cols: "4" } },
              [
                _c("label", { staticClass: "selecter-label" }, [
                  _vm._v("Reference no."),
                ]),
                _c("b-form-input", {
                  model: {
                    value: _vm.info.reference_no,
                    callback: function ($$v) {
                      _vm.$set(_vm.info, "reference_no", $$v)
                    },
                    expression: "info.reference_no",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-col",
              { attrs: { cols: "4" } },
              [
                _c("label", { staticClass: "selecter-label" }, [
                  _vm._v("No. visits/units"),
                ]),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "6" } },
                      [
                        _c("b-form-input", {
                          attrs: { type: "number" },
                          model: {
                            value: _vm.info.quantity_approved,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "quantity_approved", $$v)
                            },
                            expression: "info.quantity_approved",
                          },
                        }),
                        _c("div", { staticClass: "avo-text-light ml-2" }, [
                          _vm._v("Approved"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "6 pl-0" } },
                      [
                        _c("b-form-input", {
                          attrs: { type: "number" },
                          model: {
                            value: _vm.info.quantity_remaining,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "quantity_remaining", $$v)
                            },
                            expression: "info.quantity_remaining",
                          },
                        }),
                        _c("div", { staticClass: "avo-text-light ml-2" }, [
                          _vm._v("Remaining"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }