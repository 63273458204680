var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.patientSubmitted && !_vm.selected
    ? _c(
        "div",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-between mb-3",
                  attrs: { md: "8", lg: "6" },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "avo-primary-btn",
                      on: { click: _vm.showNewPatientCard },
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "plus" },
                      }),
                      _vm._v("Add Patient "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.created
            ? _c(
                "div",
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-3",
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c("strong", [_vm._v("New Patient")]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "inline-block",
                                      float: "right",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "close-btn",
                                        on: { click: _vm.clear },
                                      },
                                      [
                                        _c("b-icon", {
                                          staticClass: "mr-2",
                                          attrs: { icon: "x" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2284706442
                      ),
                    },
                    [
                      _c(
                        "b-row",
                        { staticClass: "mb-lg-3", attrs: { "align-v": "end" } },
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "6", lg: "3" } },
                            [
                              _c("label", [_vm._v("Patient's first name")]),
                              _c("b-form-input", {
                                attrs: {
                                  state: _vm.fieldStates(
                                    "patient",
                                    "given_name"
                                  ),
                                  placeholder: "First name",
                                },
                                model: {
                                  value: _vm.newPatient.given_name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newPatient, "given_name", $$v)
                                  },
                                  expression: "newPatient.given_name",
                                },
                              }),
                              _c("b-form-invalid-feedback", [
                                _vm._v(
                                  _vm._s(
                                    _vm.fieldErrMessages(
                                      "patient",
                                      "given_name"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "6", lg: "3" } },
                            [
                              _c("label", [_vm._v("Last name")]),
                              _c("b-form-input", {
                                attrs: {
                                  state: _vm.fieldStates(
                                    "patient",
                                    "family_name"
                                  ),
                                  placeholder: "First name",
                                },
                                model: {
                                  value: _vm.newPatient.family_name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newPatient, "family_name", $$v)
                                  },
                                  expression: "newPatient.family_name",
                                },
                              }),
                              _c("b-form-invalid-feedback", [
                                _vm._v(
                                  _vm._s(
                                    _vm.fieldErrMessages(
                                      "patient",
                                      "family_name"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "6", lg: "3" } },
                            [
                              _c("label", [_vm._v("Account number")]),
                              _c("b-form-input", {
                                attrs: { placeholder: "Acct #" },
                                model: {
                                  value: _vm.newPatient.account_no,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newPatient, "account_no", $$v)
                                  },
                                  expression: "newPatient.account_no",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "6", lg: "3" } },
                            [
                              _c("label", [_vm._v("DOB")]),
                              _c("b-form-input", {
                                attrs: {
                                  state: _vm.fieldStates(
                                    "patient",
                                    "date_of_birth"
                                  ),
                                  type: "date",
                                  placeholder: "MM/DD/YYYY",
                                },
                                model: {
                                  value: _vm.newPatient.date_of_birth,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.newPatient,
                                      "date_of_birth",
                                      $$v
                                    )
                                  },
                                  expression: "newPatient.date_of_birth",
                                },
                              }),
                              _c("b-form-invalid-feedback", [
                                _vm._v(
                                  _vm._s(
                                    _vm.fieldErrMessages(
                                      "patient",
                                      "date_of_birth"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { attrs: { "align-v": "end" } },
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "6", lg: "3" } },
                            [
                              _c("label", [_vm._v("Primary phone")]),
                              _c("b-form-input", {
                                attrs: {
                                  state: _vm.fieldStates(
                                    "address",
                                    "telephone"
                                  ),
                                  type: "tel",
                                  placeholder: "XXX-XXX-XXXX",
                                },
                                model: {
                                  value: _vm.newAddress.telephone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newAddress, "telephone", $$v)
                                  },
                                  expression: "newAddress.telephone",
                                },
                              }),
                              _c("b-form-invalid-feedback", [
                                _vm._v(
                                  _vm._s(
                                    _vm.fieldErrMessages("address", "telephone")
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "6", lg: "3" } },
                            [
                              _c("label", [_vm._v("Email")]),
                              _c("b-form-input", {
                                attrs: {
                                  state: _vm.fieldStates("address", "email"),
                                  type: "email",
                                  placeholder: "email@email.com",
                                },
                                model: {
                                  value: _vm.newAddress.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newAddress, "email", $$v)
                                  },
                                  expression: "newAddress.email",
                                },
                              }),
                              _c("b-form-invalid-feedback", [
                                _vm._v(
                                  _vm._s(
                                    _vm.fieldErrMessages("address", "email")
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "12", lg: "6" } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "mb-3",
                                  attrs: { switch: "" },
                                  model: {
                                    value: _vm.isNewPatient,
                                    callback: function ($$v) {
                                      _vm.isNewPatient = $$v
                                    },
                                    expression: "isNewPatient",
                                  },
                                },
                                [_c("span", [_vm._v("New patient")])]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-primary-btn ml-md-3 ml-auto",
                          on: { click: _vm.addPatient },
                        },
                        [_vm._v("Save New Patient")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }