var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "preauth-edit" } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "preauth-edit",
            title: "Edit Preauth",
            "hide-footer": "",
            size: "lg",
          },
        },
        [
          _c(
            "b-form",
            [
              _c(
                "b-alert",
                {
                  attrs: { variant: "danger", dismissible: "" },
                  model: {
                    value: _vm.showAlert,
                    callback: function ($$v) {
                      _vm.showAlert = $$v
                    },
                    expression: "showAlert",
                  },
                },
                [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
              ),
              _c(
                "div",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("label", { staticClass: "selecter-label" }, [
                            _vm._v("Status"),
                          ]),
                          _c("b-form-select", {
                            attrs: { options: _vm.preauthStateMap },
                            scopedSlots: _vm._u([
                              {
                                key: "first",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: null, disabled: "" } },
                                      [_vm._v("-- Please select an option --")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.preauth_state,
                              callback: function ($$v) {
                                _vm.preauth_state = $$v
                              },
                              expression: "preauth_state",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("label", { staticClass: "selecter-label" }, [
                            _vm._v("Effective start date"),
                          ]),
                          _c("b-form-input", {
                            attrs: {
                              type: "date",
                              placeholder: "Choose a date",
                              "date-format-options": {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                              },
                            },
                            model: {
                              value: _vm.effective_start_date,
                              callback: function ($$v) {
                                _vm.effective_start_date = $$v
                              },
                              expression: "effective_start_date",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("label", { staticClass: "selecter-label" }, [
                            _vm._v("Effective end date"),
                          ]),
                          _c("b-form-input", {
                            attrs: {
                              type: "date",
                              placeholder: "Choose a date",
                              "date-format-options": {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                              },
                            },
                            model: {
                              value: _vm.effective_end_date,
                              callback: function ($$v) {
                                _vm.effective_end_date = $$v
                              },
                              expression: "effective_end_date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("label", { staticClass: "selecter-label" }, [
                            _vm._v("Insurance authorization no."),
                          ]),
                          _c("b-form-input", {
                            model: {
                              value: _vm.insurance_auth_no,
                              callback: function ($$v) {
                                _vm.insurance_auth_no = $$v
                              },
                              expression: "insurance_auth_no",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("label", { staticClass: "selecter-label" }, [
                            _vm._v("Reference no."),
                          ]),
                          _c("b-form-input", {
                            model: {
                              value: _vm.reference_no,
                              callback: function ($$v) {
                                _vm.reference_no = $$v
                              },
                              expression: "reference_no",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("label", { staticClass: "selecter-label" }, [
                            _vm._v("No. visits/units"),
                          ]),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("b-form-input", {
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.quantity_approved,
                                      callback: function ($$v) {
                                        _vm.quantity_approved = $$v
                                      },
                                      expression: "quantity_approved",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "avo-text-light ml-2" },
                                    [_vm._v("Approved")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "6 pl-0" } },
                                [
                                  _c("b-form-input", {
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.quantity_remaining,
                                      callback: function ($$v) {
                                        _vm.quantity_remaining = $$v
                                      },
                                      expression: "quantity_remaining",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "avo-text-light ml-2" },
                                    [_vm._v("Remaining")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "mt-4",
                      staticStyle: { "text-align": "right" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-primary-btn",
                          attrs: {
                            variant: "outline-primary",
                            disabled: _vm.submitting,
                          },
                          on: { click: _vm.submitPreauth },
                        },
                        [
                          _c(
                            "b-overlay",
                            {
                              attrs: { show: _vm.submitting, variant: "white" },
                            },
                            [_vm._v("Submit Preauth Changes")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }