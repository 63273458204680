var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          !_vm.isQuickView
            ? _c(
                "b-button",
                { on: { click: _vm.goPreauthDashboard } },
                [
                  _c("b-icon", { attrs: { icon: "chevron-left" } }),
                  _vm._v(" Back to preauths"),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("b-container", { staticClass: "preauthmanage-container" }, [
        _c(
          "div",
          { staticClass: "preauthmanage-header" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "6" } },
                  [
                    _c(
                      "b-card",
                      { staticClass: "patient-display shadow ml-2" },
                      [
                        _c("b-row", { staticClass: "mb-3" }, [
                          _c("div", { staticClass: "display-header" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.selectedPreauth.patient.full_name) +
                                " "
                            ),
                          ]),
                        ]),
                        _c(
                          "b-row",
                          { staticClass: "mb-3" },
                          [
                            _c(
                              "b-col",
                              [
                                _c("b-row", [
                                  _c("div", { staticClass: "display-light" }, [
                                    _vm._v("Account No."),
                                  ]),
                                ]),
                                _c("b-row", [
                                  _vm._v(
                                    " #" +
                                      _vm._s(
                                        _vm.selectedPreauth.patient
                                          .account_no || "N/A"
                                      ) +
                                      " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c("b-row", [
                                  _c("div", { staticClass: "display-light" }, [
                                    _vm._v("DOB"),
                                  ]),
                                ]),
                                _c("b-row", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.selectedPreauth.patient
                                          .date_of_birth
                                          ? _vm.formatDateShort(
                                              _vm.selectedPreauth.patient
                                                .date_of_birth
                                            )
                                          : "N/A"
                                      ) +
                                      " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("b-row", { staticClass: "mb-1" }, [
                          _c(
                            "div",
                            { staticClass: "contact-info" },
                            [
                              _c("b-icon", {
                                staticStyle: { "margin-right": "5px" },
                                attrs: { icon: "telephone" },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedPreauth.patient_phone || "N/A"
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("b-row", [
                          _c(
                            "div",
                            { staticClass: "contact-info" },
                            [
                              _c("b-icon", {
                                staticStyle: { "margin-right": "5px" },
                                attrs: { icon: "envelope" },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedPreauth.patient_email || "N/A"
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("b-col", { attrs: { cols: "6" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-content-between pr-2 py-1",
                      staticStyle: { height: "100%" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column justify-content-between w-100",
                        },
                        [
                          _vm.visitHasPreauth &&
                          (_vm.preauthStateMap[
                            _vm.selectedPreauth.preauth.state
                          ] == "Approved" ||
                            _vm.preauthStateMap[
                              _vm.selectedPreauth.preauth.state
                            ] == "No Preauth Required")
                            ? _c("div", { staticClass: "d-flex pl-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mr-1 shield-approved",
                                    staticStyle: { position: "relative" },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "shield-check",
                                        scale: "1.4",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "shield-approved ml-1" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.preauthStateMap[
                                          _vm.selectedPreauth.preauth.state
                                        ]
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _vm.visitHasPreauth &&
                              _vm.preauthStateMap[
                                _vm.selectedPreauth.preauth.state
                              ] == "Partially Approved"
                            ? _c("div", { staticClass: "d-flex pl-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mr-1 shield-partially-approved",
                                    staticStyle: { position: "relative" },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "shield-exclamation",
                                        scale: "1.4",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "shield-partially-approved ml-1",
                                  },
                                  [_vm._v("Partially Approved")]
                                ),
                              ])
                            : _vm.visitHasPreauth &&
                              _vm.preauthStateMap[
                                _vm.selectedPreauth.preauth.state
                              ] == "Denied"
                            ? _c("div", { staticClass: "d-flex pl-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mr-1 shield-denied",
                                    staticStyle: { position: "relative" },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "shield-slash",
                                        scale: "1.4",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "shield-denied ml-1" },
                                  [_vm._v("Denied")]
                                ),
                              ])
                            : _vm.visitHasPreauth
                            ? _c("div", { staticClass: "d-flex pl-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mr-1 shield-pending",
                                    staticStyle: { position: "relative" },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "shield", scale: "1.4" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "shield-pending ml-1" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.preauthStateMap[
                                          _vm.selectedPreauth.preauth.state
                                        ]
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _c("div", { staticClass: "d-flex pl-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mr-1 shield-no-preauth",
                                    staticStyle: { position: "relative" },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "shield-minus",
                                        scale: "1.4",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "shield-no-preauth ml-1" },
                                  [_vm._v("No Preauth")]
                                ),
                              ]),
                          _vm.selectedPreauth.preauth_cptcodes
                            ? _c(
                                "div",
                                { staticClass: "d-flex flex-column mb-2" },
                                _vm._l(
                                  _vm.selectedPreauth.preauth_cptcodes,
                                  function (cptCode, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: "cptCode,index:" + index,
                                        staticClass: "d-flex",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "header-data" },
                                          [_vm._v(_vm._s(cptCode.code))]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "ml-1",
                                            staticStyle: {
                                              color: "var(--gray)",
                                              "font-size": "12px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " - " +
                                                _vm._s(cptCode.short) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _c(
                                "div",
                                { staticClass: "d-flex flex-column mb-2" },
                                _vm._l(
                                  _vm.selectedPreauth.preauth_icdcodes,
                                  function (icdCode, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: "cptCode,index:" + index,
                                        staticClass: "d-flex",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "header-data" },
                                          [_vm._v(_vm._s(icdCode.code))]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "ml-1",
                                            staticStyle: {
                                              color: "var(--gray)",
                                              "font-size": "12px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " - " +
                                                _vm._s(icdCode.description) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                          _c(
                            "div",
                            [
                              _c(
                                "b-row",
                                { attrs: { "align-v": "center" } },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _c("b-icon", {
                                        staticClass: "ml-1",
                                        attrs: {
                                          icon: "calendar2",
                                          scale: "1",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pl-0",
                                      attrs: { cols: "10" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "header-data" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatFullDate(
                                                  _vm.visit.date_of_service
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { attrs: { "align-v": "center" } },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _c("b-icon", {
                                        staticClass: "ml-1",
                                        attrs: { icon: "clock", scale: "1.1" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pl-0",
                                      attrs: { cols: "10" },
                                    },
                                    [
                                      _c("div", { staticClass: "d-flex" }, [
                                        _c(
                                          "div",
                                          { staticClass: "header-data" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formatTime(
                                                    _vm.visit.start_time
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm.selectedPreauth.end_time
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "header-data ml-1",
                                              },
                                              [
                                                _vm._v(
                                                  " - " +
                                                    _vm._s(
                                                      _vm.formatTime(
                                                        _vm.visit.end_time
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { attrs: { "align-v": "center" } },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0 pl-3",
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _c("b-avatar", {
                                        attrs: { size: "1.25rem" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pl-0",
                                      attrs: { cols: "10" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "header-data" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.selectedPreauth.provider
                                                  .full_name_with_suffix ||
                                                  "No servicing provider selected"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "close-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.exitPreviewView()
                                },
                              },
                            },
                            [
                              _c("b-icon", {
                                staticClass: "mr-2",
                                attrs: { icon: "x" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm.isQuickView
          ? _c(
              "div",
              [
                _c(
                  "b-row",
                  {
                    staticStyle: {
                      "margin-top": "-40px",
                      "margin-left": "0px",
                      "margin-right": "0px",
                      "padding-top": "60px",
                    },
                  },
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-nav",
                          { staticClass: "nav-section", attrs: { tabs: "" } },
                          [
                            _c(
                              "b-nav-item",
                              {
                                attrs: {
                                  active:
                                    _vm.currentPreviewSection == "appointment",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.goToSection("appointment")
                                  },
                                },
                              },
                              [_vm._v("Appointment")]
                            ),
                            _c(
                              "b-nav-item",
                              {
                                attrs: {
                                  active: _vm.currentPreviewSection == "visit",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.goToSection("visit")
                                  },
                                },
                              },
                              [_vm._v("Visit")]
                            ),
                            _c(
                              "b-nav-item",
                              {
                                attrs: {
                                  active:
                                    _vm.currentPreviewSection ==
                                    "authorization",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.goToSection("authorization")
                                  },
                                },
                              },
                              [_vm._v("Authorization")]
                            ),
                            _c(
                              "b-nav-item",
                              {
                                attrs: {
                                  active:
                                    _vm.currentPreviewSection == "documents",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.goToSection("documents")
                                  },
                                },
                              },
                              [_vm._v("Documents")]
                            ),
                            _c(
                              "b-nav-item",
                              {
                                attrs: {
                                  active: _vm.currentPreviewSection == "notes",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.goToSection("notes")
                                  },
                                },
                              },
                              [_vm._v("Notes")]
                            ),
                            _c(
                              "b-nav-item",
                              {
                                attrs: {
                                  active:
                                    _vm.currentPreviewSection == "activity",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.goToSection("activity")
                                  },
                                },
                              },
                              [_vm._v("Activity")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { ref: "scrollable", staticClass: "quickview mx-0" },
                  [
                    _c(
                      "div",
                      {
                        ref: "appointment",
                        staticClass: "quickview-item pl-4",
                      },
                      [
                        _c("div", { staticClass: "quickview-header" }, [
                          _vm._v("Appointment"),
                        ]),
                        _c("hr"),
                        _c("claim-visit", {
                          attrs: {
                            visitId: _vm.selectedPreauth.visit_id,
                            editable: "",
                          },
                          on: {
                            visitUpdated: function ($event) {
                              return _vm.setVisit($event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { ref: "visit", staticClass: "quickview-item" },
                      [
                        _c("div", { staticClass: "quickview-header" }, [
                          _vm._v("Visit"),
                        ]),
                        _c("hr"),
                        _vm.selectedPreauth.preauth.preauth_id
                          ? _c(
                              "b-row",
                              { staticClass: "mb-5" },
                              [
                                _c("b-col", { attrs: { cols: "8" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "card-table px-3" },
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-3 header-row" },
                                        [
                                          _c(
                                            "b-col",
                                            { staticClass: "pt-2 pl-3" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "small-header-text",
                                                },
                                                [_vm._v("Procedure codes")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("cpt-codes-list", {
                                        key:
                                          "cpt-codes-list" +
                                          _vm.selectedPreauth.preauth
                                            .preauth_id,
                                        attrs: {
                                          payerId: _vm.payer_id,
                                          patientInsuranceBenefits:
                                            _vm.primaryInsurance,
                                          outlined: false,
                                          borderless: true,
                                          "table-class": "",
                                          "table-style":
                                            "border:none 0px #E2E4EB;font-size:14x;",
                                          "preauth-id":
                                            _vm.selectedPreauth.preauth
                                              .preauth_id,
                                        },
                                        on: {
                                          codesUpdated: function ($event) {
                                            return _vm.$emit("preauthsUpdated")
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "b-col",
                                  { staticClass: "pl-0", attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "card-table px-3" },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "mb-3 header-row" },
                                          [
                                            _c(
                                              "b-col",
                                              { staticClass: "pt-2 pl-3" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "small-header-text",
                                                  },
                                                  [_vm._v("Diagnosis codes")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("icd-codes-list", {
                                          attrs: {
                                            "preauth-id":
                                              _vm.selectedPreauth.preauth
                                                .preauth_id,
                                            outlined: false,
                                            borderless: true,
                                            "table-class": "",
                                            "table-style":
                                              "border:none 0px #E2E4EB;font-size:14x;",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("div", { staticClass: "no-data-text" }, [
                              _vm._v(
                                " Add a preauth to this appointment to see more visit details "
                              ),
                            ]),
                        _vm.selectedPreauth.insurances
                          ? _c("div", [
                              _c("div", { staticClass: "quickview-header" }, [
                                _vm._v("Patient Insurance"),
                              ]),
                              _c("hr"),
                              _c(
                                "div",
                                { staticClass: "card-table px-3" },
                                [
                                  _c("insurance-list", {
                                    attrs: {
                                      "patient-id":
                                        _vm.selectedPreauth.patient_id,
                                      "patient-name":
                                        _vm.selectedPreauth.patient.full_name,
                                      "table-style":
                                        "border:none;font-size:12px;",
                                      "table-class": "no-shadow",
                                      borderless: "",
                                    },
                                    on: {
                                      insurancesUpdated: function ($event) {
                                        return _vm.setInsurances($event)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        ref: "authorization",
                        staticClass: "quickview-item pl-4",
                      },
                      [
                        _c("div", { staticClass: "quickview-header" }, [
                          _vm._v("Authorization"),
                        ]),
                        _c("hr"),
                        _vm.visitHasPreauth
                          ? _c(
                              "div",
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "mb-3" },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "pr-0",
                                        attrs: { cols: "3" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "data-label" },
                                          [_vm._v("Status")]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.selectedPreauth.preauth
                                                  .state !== null
                                                  ? _vm.preauthStateMap[
                                                      _vm.selectedPreauth
                                                        .preauth.state
                                                    ]
                                                  : "N/A"
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c("b-col", { attrs: { cols: "3" } }, [
                                      _c("div", { staticClass: "data-label" }, [
                                        _vm._v("Insurance authorization no."),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.selectedPreauth.preauth
                                                .insurance_auth_no !== null
                                                ? "#" +
                                                    _vm.selectedPreauth.preauth
                                                      .insurance_auth_no
                                                : "N/A"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                    _c("b-col", { attrs: { cols: "3" } }, [
                                      _c("div", { staticClass: "data-label" }, [
                                        _vm._v("Reference no."),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.selectedPreauth.preauth
                                                .reference_no !== null
                                                ? "#" +
                                                    _vm.selectedPreauth.preauth
                                                      .reference_no
                                                : "N/A"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                    _c("b-col", { attrs: { cols: "1" } }),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "1" } },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName:
                                                  "v-b-modal.preauth-edit",
                                                modifiers: {
                                                  "preauth-edit": true,
                                                },
                                              },
                                            ],
                                            staticClass: "avo-primary-btn",
                                            attrs: {
                                              disabled: _vm.preauthEditLoading,
                                              variant: "outline-primary",
                                            },
                                          },
                                          [_vm._v("Edit")]
                                        ),
                                        _c("preauth-edit", {
                                          on: {
                                            submitted: _vm.emitPreauthUpdated,
                                            loaded: function ($event) {
                                              _vm.preauthEditLoading = false
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  { staticClass: "mb-3" },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "pr-0",
                                        attrs: { cols: "3" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "data-label" },
                                          [_vm._v("Effective dates")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center",
                                          },
                                          [
                                            _c("b-icon", {
                                              staticClass: "mr-1",
                                              attrs: { icon: "calendar2" },
                                            }),
                                            _c("div", {}, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formatDateShort(
                                                      _vm.selectedPreauth
                                                        .preauth
                                                        .effective_start_date
                                                    )
                                                  ) +
                                                  " - " +
                                                  _vm._s(
                                                    _vm.formatDateShort(
                                                      _vm.selectedPreauth
                                                        .preauth
                                                        .effective_end_date
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c("b-col", { attrs: { cols: "3" } }, [
                                      _c("div", { staticClass: "data-label" }, [
                                        _vm._v("No. visits/units"),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                        },
                                        [
                                          _c("div", { staticClass: "mr-1" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.selectedPreauth.preauth
                                                    .quantity_approved || "N/A"
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "mr-3 data-label" },
                                            [_vm._v("approved")]
                                          ),
                                          _c("div", { staticClass: "mr-1" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.selectedPreauth.preauth
                                                    .quantity_approved || "N/A"
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "data-label" },
                                            [_vm._v("remaining")]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("hr"),
                                _c(
                                  "b-overlay",
                                  {
                                    attrs: {
                                      show: _vm.insurancesLoading,
                                      variant: "white",
                                    },
                                  },
                                  [
                                    _c("payer-preauth-details", {
                                      attrs: {
                                        id:
                                          "payer-preauth-details:" +
                                          _vm.selectedPreauth.preauth.visit_id,
                                        payer: _vm.primaryInsurance,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c("div", { staticClass: "no-data-text" }, [
                              _vm._v(
                                " Add a preauth to this appointment to see authorization details "
                              ),
                            ]),
                      ]
                    ),
                    _c(
                      "div",
                      { ref: "documents", staticClass: "quickview-item" },
                      [
                        _c("div", { staticClass: "quickview-header" }, [
                          _vm._v("Documents"),
                        ]),
                        _c("hr"),
                        _vm.visitHasPreauth
                          ? _c("document-list", {
                              key:
                                "preauthId:" +
                                _vm.selectedPreauth.preauth.preauth_id,
                              attrs: {
                                idType: "preauth",
                                id: _vm.selectedPreauth.preauth.preauth_id,
                                perPage: "6",
                                mode: "list",
                                hideDropdown: true,
                              },
                            })
                          : _c("div", { staticClass: "no-data-text" }, [
                              _vm._v(
                                " Add preauth to this appointment to use Documents "
                              ),
                            ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { ref: "notes", staticClass: "quickview-item" },
                      [
                        _c("div", { staticClass: "quickview-header" }, [
                          _vm._v("Notes"),
                        ]),
                        _c("hr"),
                        _vm.visitHasPreauth
                          ? _c("notes-list", {
                              attrs: {
                                preauthId:
                                  _vm.selectedPreauth.preauth.preauth_id,
                                mode: "list",
                              },
                            })
                          : _c("div", { staticClass: "no-data-text" }, [
                              _vm._v(
                                " Add preauth to this appointment to use Notes "
                              ),
                            ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { ref: "activity", staticClass: "quickview-item mb-5" },
                      [
                        _c("div", { staticClass: "quickview-header" }, [
                          _vm._v("Activity"),
                        ]),
                        _c("hr"),
                        _vm.visitHasPreauth
                          ? _c("activity-manager", {
                              attrs: {
                                "preauth-id":
                                  _vm.selectedPreauth.preauth.preauth_id,
                                "per-page": 10,
                              },
                            })
                          : _c("div", { staticClass: "no-data-text" }, [
                              _vm._v(
                                " Add preauth to this appointment to see Activity "
                              ),
                            ]),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            )
          : _c("div", [
              _vm._v(
                " Sorry, something went wrong and we were unable to load your visit. "
              ),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }