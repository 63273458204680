var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "preauthadd-container" },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "2" } }, [
                    _c(
                      "div",
                      { staticClass: "pb-2" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "avo-danger-btn mb-2",
                            on: { click: _vm.goPreauthDashboard },
                          },
                          [
                            _c("b-icon", {
                              staticClass: "mr-2",
                              attrs: { icon: "arrow-left-circle" },
                            }),
                            _vm._v("Cancel Request"),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { staticClass: "preauthadd-header pl-2" }, [
                    _c("h4", { staticClass: "mb-0 mt-2" }, [
                      _vm._v(
                        _vm._s(
                          _vm.doCreateVisit
                            ? "New preauthorization request"
                            : "Add preauth for selected visit"
                        )
                      ),
                    ]),
                  ]),
                  _c("br"),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "preauthadd-sidebar pr-0 pl-0",
                  attrs: { cols: "2" },
                },
                [
                  _c(
                    "b-nav",
                    [
                      _vm.doCreateVisit
                        ? _c(
                            "b-nav-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goToSection("1")
                                },
                              },
                            },
                            [
                              _c("b-avatar", {
                                attrs: { text: "1", size: "1.5rem" },
                              }),
                              _vm._v(" Patient information"),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.doCreateVisit
                        ? _c(
                            "b-nav-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goToSection("2")
                                },
                              },
                            },
                            [
                              _c("b-avatar", {
                                attrs: { text: "2", size: "1.5rem" },
                              }),
                              _vm._v(" Patient's insurance"),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.doCreateVisit
                        ? _c(
                            "b-nav-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goToSection("3")
                                },
                              },
                            },
                            [
                              _c("b-avatar", {
                                attrs: { text: "3", size: "1.5rem" },
                              }),
                              _vm._v(" Appointment details"),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-nav-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.goToSection("4")
                            },
                          },
                        },
                        [
                          _c("b-avatar", {
                            attrs: { text: "4", size: "1.5rem" },
                          }),
                          _vm._v(" CPT & ICD codes"),
                        ],
                        1
                      ),
                      _c(
                        "b-nav-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.goToSection("5")
                            },
                          },
                        },
                        [
                          _c("b-avatar", {
                            attrs: { text: "5", size: "1.5rem" },
                          }),
                          _vm._v(" Authorization information"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "form-area p-3 pb-5", attrs: { cols: "10" } },
                [
                  _vm._l(_vm.alertMessages, function (alert, index) {
                    return _c(
                      "b-alert",
                      {
                        key: index,
                        attrs: { show: _vm.showAlerts, variant: "danger" },
                      },
                      [_c("b", [_vm._v(_vm._s(alert))])]
                    )
                  }),
                  _c(
                    "b-overlay",
                    {
                      attrs: {
                        "spinner-type": "grow",
                        "spinner-small": "",
                        show: !_vm.doCreateVisit,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "overlay",
                          fn: function () {
                            return [_c("div")]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "section-header-text d-flex align-items-center",
                        },
                        [
                          _c("b-avatar", {
                            ref: "1",
                            staticClass: "mr-2",
                            attrs: { text: "1", size: "2rem" },
                          }),
                          _vm._v(" Patient Information "),
                        ],
                        1
                      ),
                      _c("hr"),
                    ]
                  ),
                  _vm.doCreateVisit
                    ? _c(
                        "div",
                        { staticClass: "patient-info-section mb-4" },
                        [
                          _c("div", { staticClass: "multiselect-label" }, [
                            _vm._v("Search existing patients"),
                          ]),
                          _c("patient-selection", { staticClass: "mb-4" }),
                          _c("patient-display", { staticClass: "mb-4" }),
                          !_vm.patientSelected
                            ? _c("div", { staticClass: "mb-2" }, [
                                _vm._v("Or add a new patient"),
                              ])
                            : _vm._e(),
                          _c("create-patient", {
                            staticClass: "mb-4",
                            staticStyle: { "max-width": "1000px" },
                          }),
                          !_vm.patientSubmitted && _vm.patientSelected
                            ? _c(
                                "div",
                                {},
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "avo-primary-btn",
                                      on: {
                                        click: _vm.checkSelectedPatientValid,
                                      },
                                    },
                                    [_vm._v("Confirm")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-overlay",
                    {
                      attrs: {
                        "spinner-type": "grow",
                        "spinner-small": "",
                        show: !_vm.doCreateVisit,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "overlay",
                          fn: function () {
                            return [_c("div")]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "section-header-text d-flex align-items-center",
                        },
                        [
                          _c("b-avatar", {
                            ref: "2",
                            staticClass: "mr-2",
                            attrs: { text: "2", size: "2rem" },
                          }),
                          _vm._v(" Patient's Insurance "),
                        ],
                        1
                      ),
                      _c("hr"),
                    ]
                  ),
                  _vm.doCreateVisit
                    ? _c("patient-insurance-preauth", { staticClass: "mb-5" })
                    : _vm._e(),
                  _c(
                    "b-overlay",
                    {
                      attrs: {
                        "spinner-type": "grow",
                        "spinner-small": "",
                        show: !_vm.doCreateVisit,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "overlay",
                          fn: function () {
                            return [_c("div")]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "section-header-text d-flex align-items-center",
                        },
                        [
                          _c("b-avatar", {
                            ref: "3",
                            staticClass: "mr-2",
                            attrs: { text: "3", size: "2rem" },
                          }),
                          _vm._v(" Appointment Details "),
                        ],
                        1
                      ),
                      _c("hr"),
                    ]
                  ),
                  _vm.doCreateVisit
                    ? _c("visit-add", {
                        staticClass: "mb-5",
                        staticStyle: { "max-width": "1000px" },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "section-header-text d-flex align-items-center",
                    },
                    [
                      _c("b-avatar", {
                        ref: "4",
                        staticClass: "mr-2",
                        attrs: { text: "4", size: "2rem" },
                      }),
                      _vm._v(" CPT & ICD Codes "),
                    ],
                    1
                  ),
                  _c("hr"),
                  _vm.patientSubmitted &&
                  _vm.insurancesSubmitted &&
                  _vm.visitSubmitted
                    ? _c(
                        "div",
                        [
                          _c("preauth-add-codes", {
                            staticClass: "mb-5",
                            attrs: { "practice-id": _vm.practiceId },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c("b-avatar", {
                        ref: "5",
                        staticClass: "mr-2",
                        attrs: { text: "5", size: "2rem" },
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column justify-content-end pt-3",
                        },
                        [
                          _c("div", { staticClass: "section-header-text" }, [
                            _vm._v("Authorization Information"),
                          ]),
                          _c("i", { staticClass: "avo-text-light" }, [
                            _vm._v("This section is optional"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("hr"),
                  _vm.patientSubmitted &&
                  _vm.insurancesSubmitted &&
                  _vm.visitSubmitted &&
                  _vm.codesSaved
                    ? _c(
                        "div",
                        [_c("preauth-add-auth-info", { staticClass: "mb-5" })],
                        1
                      )
                    : _vm._e(),
                  _vm.patientSubmitted &&
                  _vm.insurancesSubmitted &&
                  _vm.visitSubmitted &&
                  _vm.codesSaved
                    ? _c(
                        "div",
                        { staticClass: "d-flex mt-5" },
                        [
                          _c(
                            "b-overlay",
                            {
                              attrs: {
                                variant: "white",
                                show: _vm.createPreauthLoading,
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "avo-primary-btn",
                                  attrs: { variant: "outline-primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.savePreauth()
                                    },
                                  },
                                },
                                [
                                  _vm._v(" Create Preauth "),
                                  _c("b-icon", {
                                    staticClass: "ml-2",
                                    attrs: { icon: "calendar2-check" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }