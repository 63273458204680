var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.patient
    ? _c("div", [
        !_vm.patientSubmitted && _vm.selected
          ? _c(
              "div",
              [
                _c("br"),
                _c("div", { staticClass: "info" }, [
                  _vm._v(
                    "Please confirm this is the correct patient. If not, please remove and select a new patient"
                  ),
                ]),
                _c(
                  "b-card-group",
                  {
                    staticStyle: {
                      "margin-top": "10px",
                      "max-width": "1000px",
                    },
                  },
                  [
                    _c(
                      "b-card",
                      {
                        staticClass: "patient-select shadow",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c("b-icon", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      icon: "check-circle",
                                      "font-size": "1.5rem",
                                    },
                                  }),
                                  _vm._v(" Patient Selected "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        float: "right",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "close-btn",
                                          on: { click: _vm.clearPatient },
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass: "mr-2",
                                            attrs: { icon: "x" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "card-top-body" },
                                    [
                                      _c("b-table", {
                                        staticClass: "patient-table",
                                        attrs: {
                                          items: [_vm.patient],
                                          fields: _vm.patientDisplayFields,
                                          borderless: true,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3592534883
                        ),
                      },
                      [
                        _c(
                          "b-container",
                          { staticStyle: { "margin-top": "30px" } },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  [
                                    _c("label", [_vm._v("Primary phone")]),
                                    _c("b-form-input", {
                                      attrs: {
                                        state: _vm.fieldStates("telephone"),
                                      },
                                      model: {
                                        value: _vm.newTelephone,
                                        callback: function ($$v) {
                                          _vm.newTelephone = $$v
                                        },
                                        expression: "newTelephone",
                                      },
                                    }),
                                    _c("b-form-invalid-feedback", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.fieldErrMessages("telephone")
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  [
                                    _c("label", [_vm._v("Email")]),
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "email",
                                        state: _vm.fieldStates("email"),
                                      },
                                      model: {
                                        value: _vm.newEmail,
                                        callback: function ($$v) {
                                          _vm.newEmail = $$v
                                        },
                                        expression: "newEmail",
                                      },
                                    }),
                                    _c("b-form-invalid-feedback", [
                                      _vm._v(
                                        _vm._s(_vm.fieldErrMessages("email"))
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          bottom: "0",
                                        },
                                        attrs: { switch: "" },
                                        model: {
                                          value: _vm.newPatient,
                                          callback: function ($$v) {
                                            _vm.newPatient = $$v
                                          },
                                          expression: "newPatient",
                                        },
                                      },
                                      [_c("span", [_vm._v("New P.T.")])]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm.patientSubmitted
          ? _c(
              "div",
              [
                _c("br"),
                _c(
                  "b-card",
                  { staticClass: "patient-display shadow" },
                  [
                    _c(
                      "b-container",
                      [
                        _c("b-row", [
                          _c("div", { staticClass: "display-header" }, [
                            _vm._v(" " + _vm._s(_vm.patient.full_name) + " "),
                          ]),
                        ]),
                        _c("br"),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("b-row", [
                                  _c("div", { staticClass: "display-light" }, [
                                    _vm._v(" Account No. "),
                                  ]),
                                ]),
                                _c("b-row", [
                                  _vm._v(
                                    " #" + _vm._s(_vm.patient.account_no) + " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c("b-row", [
                                  _c("div", { staticClass: "display-light" }, [
                                    _vm._v(" DOB "),
                                  ]),
                                ]),
                                _c("b-row", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatDateShort(
                                          _vm.patient.date_of_birth
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      bottom: "0",
                                    },
                                    attrs: { switch: "", disabled: "" },
                                    model: {
                                      value: _vm.newPatient,
                                      callback: function ($$v) {
                                        _vm.newPatient = $$v
                                      },
                                      expression: "newPatient",
                                    },
                                  },
                                  [_c("span", [_vm._v("New patient")])]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("br"),
                        _c("b-row", [
                          _c(
                            "div",
                            { staticClass: "display-light" },
                            [
                              _c("b-icon", {
                                staticStyle: { "margin-right": "5px" },
                                attrs: { icon: "telephone" },
                              }),
                              _vm._v(" " + _vm._s(_vm.newTelephone) + " "),
                            ],
                            1
                          ),
                        ]),
                        _c("b-row", [
                          _c(
                            "div",
                            { staticClass: "display-light" },
                            [
                              _c("b-icon", {
                                staticStyle: { "margin-right": "5px" },
                                attrs: { icon: "envelope" },
                              }),
                              _vm._v(" " + _vm._s(_vm.newEmail) + " "),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }