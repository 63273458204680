var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-alert",
        {
          attrs: { variant: "danger", dismissible: "" },
          model: {
            value: _vm.showAlert,
            callback: function ($$v) {
              _vm.showAlert = $$v
            },
            expression: "showAlert",
          },
        },
        [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
      ),
      _c("div", { staticClass: "mb-2", staticStyle: { "font-size": "18px" } }, [
        _vm._v("Diagnosis Codes"),
      ]),
      _c(
        "b-overlay",
        { attrs: { show: _vm.icdCodesLoading, variant: "white" } },
        [
          _c(
            "b-table-simple",
            {
              staticClass: "shadow mb-4",
              staticStyle: {
                border: "solid 1px #E2E4EB",
                "font-size": "14px",
                "background-color": "white",
              },
            },
            [
              _c(
                "b-thead",
                [
                  _c(
                    "b-tr",
                    [
                      _c("b-th", [_vm._v(" ICD-10 ")]),
                      _c("b-th", [_vm._v(" Description ")]),
                      _c("b-th", [_vm._v(" Pointer ")]),
                      _c("b-th"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-tbody",
                [
                  _vm._l(_vm.icdCodes, function (code, index) {
                    return _c(
                      "b-tr",
                      { key: index },
                      [
                        _c("b-td", [
                          _c("div", { staticClass: "mt-2" }, [
                            _vm._v(" " + _vm._s(code.code || "-") + " "),
                          ]),
                        ]),
                        _c(
                          "b-td",
                          { staticStyle: { "overflow-wrap": "break-word" } },
                          [
                            _c("div", { staticClass: "mt-2" }, [
                              _vm._v(
                                " " + _vm._s(code.description || "N/A") + " "
                              ),
                            ]),
                          ]
                        ),
                        _c("b-td", [
                          _c("div", { staticClass: "mt-2" }, [
                            _vm._v(" " + _vm._s(code.pointer || "-") + " "),
                          ]),
                        ]),
                        _c(
                          "b-td",
                          { staticClass: "text-right" },
                          [
                            _c(
                              "b-dropdown",
                              {
                                staticClass: "avo-action-dropdown",
                                staticStyle: { "min-width": "none" },
                                attrs: { "no-caret": "", right: true },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "button-content",
                                      fn: function () {
                                        return [
                                          _c("b-icon", {
                                            attrs: { icon: "three-dots" },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewEditICDModal(index)
                                      },
                                    },
                                  },
                                  [_vm._v("Edit pointer")]
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteICDCode(index)
                                      },
                                    },
                                  },
                                  [_vm._v("Remove")]
                                ),
                                _c(
                                  "b-modal",
                                  {
                                    attrs: {
                                      id: "edit-icd-modal-" + index,
                                      "hide-footer": "",
                                      "hide-header": "",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "avo-header-text",
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_vm._v(" Edit ICD Code Pointer ")]
                                    ),
                                    _c("hr"),
                                    _c(
                                      "b-alert",
                                      {
                                        attrs: {
                                          variant: "danger",
                                          dismissible: "",
                                        },
                                        model: {
                                          value: _vm.showAlertModal,
                                          callback: function ($$v) {
                                            _vm.showAlertModal = $$v
                                          },
                                          expression: "showAlertModal",
                                        },
                                      },
                                      [
                                        _c("b", [
                                          _vm._v(_vm._s(_vm.errorTextModal)),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  id: "pointer",
                                                  label: "Pointer:",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-input-group",
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "number",
                                                        min: "0",
                                                      },
                                                      model: {
                                                        value: _vm.newPointer,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.newPointer = $$v
                                                        },
                                                        expression:
                                                          "newPointer",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "avo-basic-btn mr-2",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.closeEditICDModal(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" Cancel ")]
                                            ),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "avo-primary-btn",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.submitICDEdit(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" Submit New Pointer ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "b-tr",
                    [
                      _c("b-td", { attrs: { colspan: "4" } }, [
                        _c(
                          "div",
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-modal",
                                    rawName: "v-b-modal.new-icd-code",
                                    modifiers: { "new-icd-code": true },
                                  },
                                ],
                                staticClass: "avo-primary-btn mr-2",
                                staticStyle: { width: "150px", height: "30px" },
                              },
                              [
                                _c("b-icon", {
                                  staticClass: "mr-2",
                                  attrs: { icon: "plus-circle" },
                                }),
                                _vm._v(" Add ICD code "),
                              ],
                              1
                            ),
                            _c("icd-code-add", {
                              attrs: {
                                newPreauth: true,
                                "current-codes": _vm.icdCodes,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "mb-2", staticStyle: { "font-size": "18px" } }, [
        _vm._v("CPT Codes"),
      ]),
      _c(
        "b-overlay",
        { attrs: { show: _vm.cptCodesLoading, variant: "white" } },
        [
          _c(
            "b-table-simple",
            {
              staticClass: "shadow",
              staticStyle: {
                border: "solid 1px #E2E4EB",
                "font-size": "14px",
                "background-color": "white",
              },
            },
            [
              _c(
                "b-thead",
                [
                  _c(
                    "b-tr",
                    [
                      _c("b-th", [_vm._v(" CPT Codes ")]),
                      _c("b-th", [_vm._v(" Modifiers ")]),
                      _c("b-th", [_vm._v(" Description ")]),
                      _c("b-th", { staticClass: "text-right" }, [
                        _vm._v(" Charge "),
                      ]),
                      _c("b-th", [_vm._v(" Units ")]),
                      _c("b-th", [_vm._v(" POS ")]),
                      _c("b-th", [_vm._v(" TOS ")]),
                      _c("b-th", { staticClass: "text-right" }, [
                        _vm._v(" Total "),
                      ]),
                      _c("b-th"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-tbody",
                [
                  _vm._l(_vm.cptCodes, function (code, index) {
                    return _c(
                      "b-tr",
                      { key: index },
                      [
                        _c("b-td", [
                          _c("div", { staticClass: "mt-2" }, [
                            _vm._v(" " + _vm._s(code.code) + ": "),
                            _c(
                              "span",
                              { staticClass: "avo-text-light-small" },
                              [_vm._v(_vm._s(code.short || "-"))]
                            ),
                          ]),
                        ]),
                        _c("b-td", [
                          code.modifiers
                            ? _c(
                                "div",
                                [
                                  code.modifiers.length > 0 && code.modifiers[0]
                                    ? _c(
                                        "b-badge",
                                        {
                                          staticClass: "mr-2 avo-badge",
                                          staticStyle: {
                                            "min-width": "auto!important",
                                            padding: "5px",
                                            "font-size": "10px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(code.modifiers[0]) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  code.modifiers.length > 1 && code.modifiers[1]
                                    ? _c(
                                        "b-badge",
                                        {
                                          staticClass: "mr-2 mt-1 avo-badge",
                                          staticStyle: {
                                            "min-width": "auto!important",
                                            padding: "5px",
                                            "font-size": "10px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(code.modifiers[1]) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  code.modifiers.length > 2 && code.modifiers[2]
                                    ? _c(
                                        "b-badge",
                                        {
                                          staticClass: "mr-2 mt-1 avo-badge",
                                          staticStyle: {
                                            "min-width": "auto!important",
                                            padding: "5px",
                                            "font-size": "10px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(code.modifiers[2]) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  code.modifiers.length > 3 && code.modifiers[3]
                                    ? _c(
                                        "b-badge",
                                        {
                                          staticClass: "mr-2 mt-1 avo-badge",
                                          staticStyle: {
                                            "min-width": "auto!important",
                                            padding: "5px",
                                            "font-size": "10px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(code.modifiers[3]) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _c(
                          "b-td",
                          {
                            staticStyle: {
                              "max-width": "425px",
                              width: "425px",
                              "overflow-wrap": "break-word",
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "avo-text-light-small" },
                              [_vm._v(_vm._s(code.medium || "-"))]
                            ),
                          ]
                        ),
                        _c("b-td", [
                          _c("div", { staticClass: "mt-2 text-right" }, [
                            _vm._v(
                              " $" +
                                _vm._s(Number(code.unit_charge).toFixed(2)) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("b-td", [
                          _c("div", { staticClass: "mt-2" }, [
                            _vm._v(" " + _vm._s(code.units || 0) + " "),
                          ]),
                        ]),
                        _c("b-td", [
                          _c("div", { staticClass: "mt-2" }, [
                            _vm._v(" " + _vm._s(code.pos || "-") + " "),
                          ]),
                        ]),
                        _c("b-td", [
                          _c("div", { staticClass: "mt-2" }, [
                            _vm._v(" " + _vm._s(code.tos || "-") + " "),
                          ]),
                        ]),
                        _c("b-td", [
                          _c("div", { staticClass: "mt-2 text-right" }, [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  Number(
                                    (code.unit_charge || 0) * (code.units || 0)
                                  ).toFixed(2)
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c(
                          "b-th",
                          [
                            _c(
                              "b-dropdown",
                              {
                                staticClass: "avo-action-dropdown",
                                staticStyle: { "min-width": "none" },
                                attrs: { "no-caret": "", right: true },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "button-content",
                                      fn: function () {
                                        return [
                                          _c("b-icon", {
                                            attrs: { icon: "three-dots" },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewEditCPTModal(index)
                                      },
                                    },
                                  },
                                  [_vm._v("Edit")]
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteCPTCode(index)
                                      },
                                    },
                                  },
                                  [_vm._v("Remove")]
                                ),
                                _c(
                                  "b-modal",
                                  {
                                    attrs: {
                                      id: "edit-cpt-modal-" + index,
                                      "hide-footer": "",
                                      "hide-header": "",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "avo-header-text",
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_vm._v(" Edit CPT Code ")]
                                    ),
                                    _c("hr"),
                                    _c(
                                      "b-alert",
                                      {
                                        attrs: {
                                          variant: "danger",
                                          dismissible: "",
                                        },
                                        model: {
                                          value: _vm.showAlertModal,
                                          callback: function ($$v) {
                                            _vm.showAlertModal = $$v
                                          },
                                          expression: "showAlertModal",
                                        },
                                      },
                                      [
                                        _c("b", [
                                          _vm._v(_vm._s(_vm.errorTextModal)),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  id: "units",
                                                  label: "Units:",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-input-group",
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: { type: "number" },
                                                      model: {
                                                        value:
                                                          _vm.editCPTData[
                                                            "units"
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editCPTData,
                                                            "units",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editCPTData['units']",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  id: "unit-charge",
                                                  label: "Unit charge:",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-input-group",
                                                  { attrs: { prepend: "$" } },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: { type: "number" },
                                                      model: {
                                                        value:
                                                          _vm.editCPTData[
                                                            "unit_charge"
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editCPTData,
                                                            "unit_charge",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editCPTData['unit_charge']",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  id: "pos",
                                                  label: "POS:",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-input-group",
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: { type: "number" },
                                                      model: {
                                                        value:
                                                          _vm.editCPTData[
                                                            "pos"
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editCPTData,
                                                            "pos",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editCPTData['pos']",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  id: "tos",
                                                  label: "TOS:",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-input-group",
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: { type: "number" },
                                                      model: {
                                                        value:
                                                          _vm.editCPTData[
                                                            "tos"
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editCPTData,
                                                            "tos",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editCPTData['tos']",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "avo-basic-btn mr-2",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.closeEditCPTModal(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" Cancel ")]
                                            ),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "avo-primary-btn",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.submitCPTEdit(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Submit CPT Code Changes "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "b-tr",
                    [
                      _c("b-td", { attrs: { colspan: "12" } }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-between" },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal.new-cpt-code",
                                        modifiers: { "new-cpt-code": true },
                                      },
                                    ],
                                    staticClass: "avo-primary-btn mr-2",
                                    staticStyle: {
                                      width: "150px",
                                      height: "30px",
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mr-2",
                                      attrs: { icon: "plus-circle" },
                                    }),
                                    _vm._v(" Add CPT code "),
                                  ],
                                  1
                                ),
                                _c("cpt-code-add", {
                                  attrs: {
                                    newPreauth: true,
                                    "practice-id": _vm.practiceId,
                                    "current-codes": _vm.cptCodes,
                                    selectablePointers: _vm.pointers,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "10px" } },
                              [
                                _c("span", { staticClass: "mr-3" }, [
                                  _vm._v(" Total charged: "),
                                ]),
                                _c("span", { staticClass: "bold" }, [
                                  _vm._v(" $" + _vm._s(_vm.totalCharged) + " "),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _c("b-td"),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.codesSaved
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "text-right mt-4" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "avo-primary-btn",
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.saveCodes()
                        },
                      },
                    },
                    [_vm._v("Continue")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }