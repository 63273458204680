var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-container", [
    _vm.showNewPreauth
      ? _c(
          "div",
          [
            _c("preauth-add", {
              attrs: { visitId: _vm.selectedPreauthVisitId },
            }),
          ],
          1
        )
      : _vm._e(),
    _c("div", [_c("preauth-schedule")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }