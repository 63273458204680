var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.insuranceSubmitted && _vm.patientSubmitted
    ? _c(
        "div",
        { staticClass: "appointment-container mb-5" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("label", { staticClass: "selecter-label" }, [
                    _vm._v("Practice"),
                  ]),
                  _c(
                    "b-input-group",
                    [
                      _c(
                        "multiselect",
                        {
                          class:
                            _vm.visitFieldStates("practice_id") == false
                              ? "invalid"
                              : "",
                          attrs: {
                            openDirection: "bottom",
                            placeholder: "Search by practice name",
                            "custom-label": _vm.practiceLabel,
                            "track-by": "practice_id",
                            options: _vm.selectablePractices,
                            loading: _vm.practicesLoading,
                            "internal-search": false,
                            "show-labels": false,
                            showNoOptions: true,
                            "allow-empty": false,
                          },
                          on: { "search-change": _vm.debouncePractices },
                          model: {
                            value: _vm.selectedPractice,
                            callback: function ($$v) {
                              _vm.selectedPractice = $$v
                            },
                            expression: "selectedPractice",
                          },
                        },
                        [
                          _c("template", { slot: "noResult" }, [
                            _vm._v(" No practices matched your search "),
                          ]),
                          _c("template", { slot: "noOptions" }, [
                            _vm._v(" Start typing a practice name... "),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "d-flex justify-content-center my-2" }, [
            _c(
              "div",
              [
                _c(
                  "b-alert",
                  {
                    staticStyle: { width: "750px" },
                    attrs: {
                      ariant: _vm.alertError ? "danger" : "success",
                      dismissible: "",
                    },
                    model: {
                      value: _vm.showAlert,
                      callback: function ($$v) {
                        _vm.showAlert = $$v
                      },
                      expression: "showAlert",
                    },
                  },
                  [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
                ),
              ],
              1
            ),
          ]),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("label", { staticClass: "selecter-label" }, [
                    _vm._v("Service Facility Location"),
                  ]),
                  _c(
                    "b-input-group",
                    [
                      _c(
                        "multiselect",
                        {
                          class:
                            _vm.visitFieldStates("practice_location_id") ==
                            false
                              ? "invalid"
                              : "",
                          attrs: {
                            openDirection: "bottom",
                            placeholder: "Search by practice name",
                            "custom-label": _vm.locationLabel,
                            "track-by": "location_id",
                            options: _vm.selectableLocations,
                            loading: _vm.locationsLoading,
                            "internal-search": false,
                            "show-labels": false,
                            showNoOptions: true,
                            disabled:
                              _vm.isNewLocation ||
                              _vm.visit.practice_id == null,
                            "allow-empty": false,
                          },
                          on: { "search-change": _vm.debounceLocations },
                          model: {
                            value: _vm.selectedLocation,
                            callback: function ($$v) {
                              _vm.selectedLocation = $$v
                            },
                            expression: "selectedLocation",
                          },
                        },
                        [
                          _c("template", { slot: "noResult" }, [
                            _vm._v(" No locations matched your search "),
                          ]),
                          _c("template", { slot: "noOptions" }, [
                            _vm._v(
                              " Start typing a location name or address... "
                            ),
                          ]),
                          _c("template", { slot: "afterList" }, [
                            _c(
                              "div",
                              {
                                staticClass: "new-practice-option",
                                on: {
                                  click: function ($event) {
                                    _vm.isNewLocation = true
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "12px 12px 0 12px",
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mr-2",
                                      attrs: { icon: "plus-circle" },
                                    }),
                                    _vm._v("New Location"),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isNewLocation
            ? _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "mb-3 pb-3",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c("strong", [_vm._v("New Location")]),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                          float: "right",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "close-btn",
                                            on: { click: _vm.clearNewLocation },
                                          },
                                          [
                                            _c("b-icon", {
                                              staticClass: "mr-2",
                                              attrs: { icon: "x" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3547777861
                          ),
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "label",
                                    { staticClass: "selecter-label" },
                                    [_vm._v("Name")]
                                  ),
                                  _c("b-form-input", {
                                    attrs: {
                                      state: _vm.locationFieldStates("name"),
                                    },
                                    model: {
                                      value: _vm.newLocation.location.name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newLocation.location,
                                          "name",
                                          $$v
                                        )
                                      },
                                      expression: "newLocation.location.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "selecter-label" },
                                    [_vm._v("PTAN")]
                                  ),
                                  _c("b-form-input", {
                                    model: {
                                      value: _vm.newLocation.location.ptan,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newLocation.location,
                                          "ptan",
                                          $$v
                                        )
                                      },
                                      expression: "newLocation.location.ptan",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "selecter-label" },
                                    [_vm._v("Fax")]
                                  ),
                                  _c("b-form-input", {
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.newLocation.address.fax,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newLocation.address,
                                          "fax",
                                          $$v
                                        )
                                      },
                                      expression: "newLocation.address.fax",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "selecter-label" },
                                    [_vm._v("Phone")]
                                  ),
                                  _c("b-form-input", {
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.newLocation.address.telephone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newLocation.address,
                                          "telephone",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "newLocation.address.telephone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "label",
                                    { staticClass: "selecter-label" },
                                    [_vm._v("Street address")]
                                  ),
                                  _c("b-form-input", {
                                    model: {
                                      value:
                                        _vm.newLocation.address.address_line1,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newLocation.address,
                                          "address_line1",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "newLocation.address.address_line1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "selecter-label" },
                                    [_vm._v("City")]
                                  ),
                                  _c("b-form-input", {
                                    model: {
                                      value: _vm.newLocation.address.city,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newLocation.address,
                                          "city",
                                          $$v
                                        )
                                      },
                                      expression: "newLocation.address.city",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "selecter-label" },
                                    [_vm._v("State")]
                                  ),
                                  _c("b-form-input", {
                                    model: {
                                      value: _vm.newLocation.address.state,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newLocation.address,
                                          "state",
                                          $$v
                                        )
                                      },
                                      expression: "newLocation.address.state",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "selecter-label" },
                                    [_vm._v("Zip")]
                                  ),
                                  _c("b-form-input", {
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.newLocation.address.zip,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newLocation.address,
                                          "zip",
                                          $$v
                                        )
                                      },
                                      expression: "newLocation.address.zip",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "text-right mt-2" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "avo-primary-btn",
                                  attrs: { disabled: _vm.creatingLocation },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitLocation()
                                    },
                                  },
                                },
                                [_vm._v("Create location")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c("label", { staticClass: "selecter-label" }, [
                    _vm._v("POS"),
                  ]),
                  _c(
                    "b-form-select",
                    {
                      attrs: {
                        disabled: !_vm.selectedLocation,
                        state: _vm.visitFieldStates("visit_pos"),
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "first",
                            fn: function () {
                              return [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: null, disabled: "" } },
                                  [_vm._v("-- Please select an option --")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1780735698
                      ),
                      model: {
                        value: _vm.visit.visit_pos,
                        callback: function ($$v) {
                          _vm.$set(_vm.visit, "visit_pos", $$v)
                        },
                        expression: "visit.visit_pos",
                      },
                    },
                    _vm._l(
                      _vm.visitOptionMap.pos_types,
                      function (option, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: idx } },
                          [
                            _vm._v(
                              " " + _vm._s(idx) + " - " + _vm._s(option) + " "
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c("label", { staticClass: "selecter-label" }, [
                    _vm._v("Tax ID"),
                  ]),
                  _vm.selectedPractice
                    ? _c("b-form-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.selectedPractice.tax_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectedPractice, "tax_id", $$v)
                          },
                          expression: "selectedPractice.tax_id",
                        },
                      })
                    : _c("b-form-input", { attrs: { disabled: "" } }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c("label", { staticClass: "selecter-label" }, [
                    _vm._v("NPI"),
                  ]),
                  _vm.selectedPractice
                    ? _c("b-form-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.selectedPractice.group_npi,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectedPractice, "group_npi", $$v)
                          },
                          expression: "selectedPractice.group_npi",
                        },
                      })
                    : _c("b-form-input", { attrs: { disabled: "" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c("label", { staticClass: "selecter-label" }, [
                    _vm._v("Visit Type"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    { attrs: { id: "visit-procedure_type" } },
                    [
                      _c("b-form-select", {
                        attrs: {
                          "text-field": "name",
                          "value-field": "procedure_type_id",
                          options: _vm.procedure_types,
                          disabled:
                            !_vm.selectedLocation ||
                            _vm.procedureTypesMapLoading,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "first",
                              fn: function () {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null, disabled: "" } },
                                    [_vm._v("-- Please select an option --")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1780735698
                        ),
                        model: {
                          value: _vm.visit.procedure_type_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.visit, "procedure_type_id", $$v)
                          },
                          expression: "visit.procedure_type_id",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c("label", { staticClass: "selecter-label" }, [
                    _vm._v("Date of service"),
                  ]),
                  _c("b-form-input", {
                    attrs: {
                      type: "date",
                      disabled: !_vm.selectedLocation,
                      placeholder: "Choose a date",
                      "date-format-options": {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      },
                      state: _vm.visitFieldStates("date_of_service"),
                    },
                    model: {
                      value: _vm.dateOfService,
                      callback: function ($$v) {
                        _vm.dateOfService = $$v
                      },
                      expression: "dateOfService",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "pr-1", attrs: { cols: "2" } },
                [
                  _c("label", { staticClass: "selecter-label" }, [
                    _vm._v("Start time"),
                  ]),
                  _c("b-form-input", {
                    attrs: {
                      type: "time",
                      disabled: !_vm.selectedLocation,
                      placeholder: "Choose a start time",
                      locale: "en",
                      state: _vm.visitFieldStates("start_time"),
                    },
                    model: {
                      value: _vm.startTime,
                      callback: function ($$v) {
                        _vm.startTime = $$v
                      },
                      expression: "startTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "pl-1", attrs: { cols: "2" } },
                [
                  _c("label", { staticClass: "selecter-label" }, [
                    _vm._v("End time"),
                  ]),
                  _c("b-form-input", {
                    attrs: {
                      type: "time",
                      disabled: !_vm.selectedLocation,
                      placeholder: "Choose an end time",
                      locale: "en",
                    },
                    model: {
                      value: _vm.endTime,
                      callback: function ($$v) {
                        _vm.endTime = $$v
                      },
                      expression: "endTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c("label", { staticClass: "selecter-label" }, [
                    _vm._v("Servicing provider"),
                  ]),
                  _c(
                    "b-input-group",
                    [
                      _c(
                        "multiselect",
                        {
                          class:
                            _vm.visitFieldStates("servicing_provider_id") ==
                            false
                              ? "invalid"
                              : "",
                          attrs: {
                            openDirection: "bottom",
                            placeholder: "Search by provider name",
                            "custom-label": _vm.providerLabel,
                            "track-by": "provider_id",
                            options: _vm.selectableProviders,
                            loading: _vm.servicingProvidersLoading,
                            "internal-search": false,
                            "show-labels": false,
                            showNoOptions: true,
                            "allow-empty": false,
                            disabled: !_vm.selectedLocation,
                          },
                          on: {
                            "search-change": _vm.debounceServicingProviders,
                          },
                          model: {
                            value: _vm.selectedServicingProvider,
                            callback: function ($$v) {
                              _vm.selectedServicingProvider = $$v
                            },
                            expression: "selectedServicingProvider",
                          },
                        },
                        [
                          _c("template", { slot: "noResult" }, [
                            _vm._v(" No providers matched your search "),
                          ]),
                          _c("template", { slot: "noOptions" }, [
                            _vm._v(" Start typing a provider name... "),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c("label", { staticClass: "selecter-label" }, [
                    _vm._v("Resource provider"),
                  ]),
                  _c(
                    "b-input-group",
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            openDirection: "bottom",
                            placeholder: "Search by provider name",
                            "custom-label": _vm.providerLabel,
                            "track-by": "provider_id",
                            options: _vm.selectableProviders,
                            loading: _vm.resourceProvidersLoading,
                            "internal-search": false,
                            "show-labels": false,
                            showNoOptions: true,
                            "allow-empty": false,
                            disabled: !_vm.selectedLocation,
                          },
                          on: {
                            "search-change": _vm.debounceResourceProviders,
                          },
                          model: {
                            value: _vm.selectedResourceProvider,
                            callback: function ($$v) {
                              _vm.selectedResourceProvider = $$v
                            },
                            expression: "selectedResourceProvider",
                          },
                        },
                        [
                          _c("template", { slot: "noResult" }, [
                            _vm._v(" No providers matched your search "),
                          ]),
                          _c("template", { slot: "noOptions" }, [
                            _vm._v(" Start typing a provider name... "),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c("label", { staticClass: "selecter-label" }, [
                    _vm._v("Referring/PCP"),
                  ]),
                  _c(
                    "b-input-group",
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            openDirection: "bottom",
                            placeholder: "Search by provider name",
                            "custom-label": _vm.referringProviderLabel,
                            "track-by": "provider_id",
                            options: _vm.selectableProviders,
                            loading: _vm.referringProvidersLoading,
                            "internal-search": false,
                            "show-labels": false,
                            showNoOptions: true,
                            "allow-empty": false,
                            disabled: !_vm.selectedLocation,
                          },
                          on: {
                            "search-change": _vm.debounceReferringProviders,
                          },
                          model: {
                            value: _vm.selectedReferringProvider,
                            callback: function ($$v) {
                              _vm.selectedReferringProvider = $$v
                            },
                            expression: "selectedReferringProvider",
                          },
                        },
                        [
                          _c("template", { slot: "noResult" }, [
                            _vm._v(" No providers matched your search "),
                          ]),
                          _c("template", { slot: "noOptions" }, [
                            _vm._v(" Start typing a provider name... "),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "text-right mt-4" },
                [
                  !_vm.visitSaved
                    ? _c(
                        "b-button",
                        {
                          staticClass: "avo-primary-btn",
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function ($event) {
                              return _vm.storeVisit()
                            },
                          },
                        },
                        [_vm._v("Continue")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }